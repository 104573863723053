import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  //{ path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: '**', redirectTo: 'home' }
  /*{ path: 'incubator', loadChildren: () => import('./incubator/incubator.module').then(m => m.IncubatorModule) },
  { path: 'valuation', loadChildren: () => import('./valuation/valuation.module').then(m => m.ValuationModule) }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
